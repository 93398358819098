.accordion{
    .accordion-item{
        border: 0;
        border-bottom: solid 1px #000000;
        padding: 0;

        .accordion-header{
            .accordion-button{
                padding: 12.5px 0;
                box-shadow: none;

                &::after{
                    display: none;
                }

                &:focus{
                    outline: none;
                    box-shadow: none;
                }

                &.collapsed{
                    .show-img{
                        display: none !important;
                    }

                    .hide-img{
                        display: block !important;
                    }
                }

                &.show{
                    border-bottom: solid 1px #9e9e9e;
                    .show-img{
                        display: block;
                    }

                    .hide-img{
                        display: none;
                    }
                }

                > img{
                    margin-left: auto;
                    width: 20px;
                }

                .hide-img{
                    display: block;
                }

                .show-img{
                    display: none;
                }
            }
        
            .accordion-button:not(.collapsed){
                background-color: white;
                color: #000000;
            }
        }

        .accordion-body{
            padding: 12.5px 0;
        }
    }
}