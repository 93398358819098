.title-divider{
    height: 2px;
    width: 60px;
    display: block;
    background-color: #0CB3F7;
    margin-bottom: 25px;

    &.primary{
        background-color: $color-primary;
    }
}

.sub-title{
    text-transform: uppercase;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.6em;

    @media (max-width: $mobile-screen) {
        font-size: 14px;
        line-height: 20px;
    }
}

.title{
    text-transform: uppercase;
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;

    @media (max-width: $mobile-screen) {
        font-size: 24px;
        line-height: 36px;
    }
}

#particles, #particles2{
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
}