/*
Color
=========================== */
$color-title: #000000;
$color-content: #120D0F;
$color-primary: #FEBC3F;
$body-bg: #FFFFFF;
$color-danger: #e74c3c;
$color-secondary: #34495e;
$color-warning: #f1c40f;
$color-success: #2ecc71;
$color-info: #3498db;
$color-light: #FFFFFF;
$color-dark: #000000;

/*
Bootstrap Custom
=========================== */
$theme-colors: (
    "primary": $color-primary,
    "danger": $color-danger,
    "secondary": $color-secondary,
    "warning": $color-warning,
    "success": $color-success,
    "info": $color-info
);

/*
Font Family
=========================== */
$font-family: 'Lato', sans-serif;;

/*
Radius Style
=========================== */
$shadow-content: 0px 6px 24px rgba(9, 3, 21, 0.18);
$shadow-overlay: 0px 6px 24px rgba(9, 3, 21, 0.18);

/*
helper spacing Style
=========================== */
$sp-0: 0;
$sp-1: 4px;
$sp-2: 8px;
$sp-3: 12px;
$sp-4: 16px;
$sp-5: 20px;
$sp-6: 24px;
$sp-7: 28px;
$sp-8: 32px;
$sp-9: 36px;
$sp-10: 40px;
$sp-11: 44px;
$sp-12: 48px;
$sp-13: 52px;
$sp-14: 56px;
$sp-15: 60px;
$sp-16: 64px;
$sp-17: 68px;
$sp-18: 72px;
$sp-19: 76px;
$sp-20: 80px;

/*
responsive width
=========================== */

$mobile-screen: 472px;
$ipad-screen: 920px;
$ipadpro-screen: 1024px;
$desktop-mini-screen: 1200px;