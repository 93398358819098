.about{
    width: 100%;
    position: relative;

    &__desc{
        margin-bottom: 60px;
    }

    &--btn{
        width: 250px;
        margin-right: 12px;

        @media (max-width: $ipadpro-screen) {
            width: 200px;
        }

        @media (max-width: $mobile-screen) {
            width: 100%;
            margin-right: 0;
            margin-bottom: 12px;
        }
    }

    &__attr-1{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 200px;
        height: 200px;
        transform: translate(5%, 64%);

        @media (max-width: $ipadpro-screen) {
            transform: translate(5%, 61%);
        }

        @media (max-width: $ipad-screen) {
            transform: translate(5%, 119%);
            width: 100px;
            height: 100px;
        }

        @media (max-width: $mobile-screen) {
            transform: translate(44%, 115%);
        }
    }

    &__attr-2{
        position: absolute;
        bottom: 20%;
        left: 18%;
        width: 100px;
        height: 40px;
        transform: translate(25%, 100%);

        @media (max-width: $ipadpro-screen) {
            transform: translate(50%, 100%);
        }

        @media (max-width: $ipad-screen) {
            transform: translate(-24%, 0%);
            bottom: -45px;
            width: 55px;
            height: 16.72px;
        }

        @media (max-width: $mobile-screen) {
            transform: translate(53%, 82%);
            left: 120px;
        }
    }
}