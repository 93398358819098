.feature{
    background-size: cover;
    color: #FFFFFF;
    padding-left: 32px;
    margin-right: -2px;
    padding-right: 34px;

    @media (max-width: $mobile-screen) {
        padding: 80px 0;

        > div{
            padding: 0;
        }
    }

    &__wrap-title{
        margin-bottom: 83px;
        max-width: calc(100% - 375px);

        @media (max-width: $ipadpro-screen) {
            max-width: 100%;
            padding: 20px;
            margin-bottom: 34px;
        }
    }

    &__owl{
        display: none !important;

        @media (max-width: $mobile-screen) {
            display: grid !important;
        }

        &-item{
            padding: 62px 20px 37px;
            text-align: center;
            height: 100%;
        }

        &-title{
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            text-transform: uppercase;
        }

        &-img{
            width: 65px !important;
            object-fit: cover;
            margin-bottom: 20px;
            display: inline-block !important;
        }
    }
    
    .grid-2 {
        --track: 1;
        display: grid;
        max-width: 100%;
        width: 100%;
        transition: grid-template 300ms;

        @media (max-width: $mobile-screen) {
            display: none !important;
        }
    }
    
    .item {
        display: grid;
        place-items: center;
        grid-template: 1fr / 1fr var(--innerTrack, 0);
        overflow: hidden;
        aspect-ratio: 8 / 1;
        transition: filter 200ms;

        &:hover {
            grid-template: 1fr / 1fr var(--innerTrack, 0);

            .desc{
                > p{
                    opacity: 1;
                    transform: translate3d(0, 0, 0);
                }
            }
        }

        .img{
            width: 45px;
            object-fit: cover;
            margin-bottom: 20px;
        }

        .desc {
            position: relative;
            width: 100%;
            height: 100%;
    
            > p {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                transform: translate3d(100%, 0, 0);
                transition: transform 300ms;
                pointer-events: none;
                padding: 30px 15px;
            }
        }
    }
    
    @media (min-width: 50em) {
        .grid-2 {
            grid-template: 1fr 1fr / 1fr 1fr 1fr;
            aspect-ratio: 2.5 / 1;
        }
        
        .item {
            aspect-ratio: auto;
        }
        
        .grid-2:has(.item:first-child:hover) {
            grid-template: 1fr 0.5fr / 1fr 0.5fr 0.5fr;
        }
    
        .grid-2:has(.item:nth-child(2):hover) {
            grid-template: 1fr 0.5fr  / 1fr 2fr 1fr;
        }
    
        .grid-2:has(.item:nth-child(3):hover) {
            grid-template: 1fr 0.5fr / 0.5fr 0.5fr 1fr;
        }
    
        .grid-2:has(.item:nth-child(4):hover) {
            grid-template: 0.5fr 1fr / 1fr 0.5fr 0.5fr;
        }

        .grid-2:has(.item:nth-child(5):hover) {
            grid-template: 0.5fr 1fr / 1fr 2fr 1fr;
        }
    
        .grid-2:has(.item:nth-child(6):hover) {
            grid-template: 0.5fr 1fr / 0.5fr 0.5fr 1fr;
        }
    }

    @media (max-width: $ipadpro-screen) {
        .grid-2 {
            grid-template: 1fr 1fr 1fr / 1fr 1fr;
            aspect-ratio: 1 / 1;
        }
        
        .item {
            aspect-ratio: auto;
        }
        
        .grid-2:has(.item:first-child:hover) {
            grid-template: 1fr 0.5fr 0.5fr / 1fr 0.5fr;
        }
    
        .grid-2:has(.item:nth-child(2):hover) {
            grid-template: 1fr 0.5fr 0.5fr / 1fr 2fr;
        }
    
        .grid-2:has(.item:nth-child(3):hover) {
            grid-template: 1fr 1fr 1fr / 1fr 0.4fr ;
        }
    
        .grid-2:has(.item:nth-child(4):hover) {
            grid-template: 1fr 1fr 1fr / 0.4fr 1fr ;
        }

        .grid-2:has(.item:nth-child(5):hover) {
            grid-template: 0.5fr 0.5fr 1fr / 1.2fr 0.5fr;
        }
    
        .grid-2:has(.item:nth-child(6):hover) {
            grid-template: 0.5fr 0.5fr 1fr / 1fr 2.2fr;
        }
    }
}