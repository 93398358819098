.owl-carousel{
    position: relative;

    .owl-nav{
        > button.owl-next, button.owl-prev{
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: $color-primary !important;

            &.owl-prev{
                position: absolute;
                left: 0;
                top: 50%;
                transform: translate(-160%, -160%);
            }

            &.owl-next{
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(160%, -160%);
            }
            
            > span{
                color: #000000;
                font-size: 54px;
                line-height: 38px;
            }
        }
    } 

    .owl-dots{
        @media (max-width: $mobile-screen) {
            display: block;
            width: calc(100% - 40px);
            margin: auto;
        }
        
        .owl-dot{
            background-color: #403C3C;
            height: 3px;
            width: 33.33%;
            max-width: 33.33%;
            flex: 1 1 auto;

            &.active{
                background-color: $color-primary;
            }
        }
    }
}