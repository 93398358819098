.services{
    padding: 100px 0;
    background-color: #222222;
    position: relative;
    z-index: 1;
    color: white;

    @media (max-width: $mobile-screen) {
        height: 576px;

        > div{
            padding: 0;
        }

        .title-style, .title-divider{
            margin-left: 20px;
        }

        .title-style{
            margin-bottom: 37px;
            font-weight: 300;
            font-size: 24px;
            line-height: 39px;
        }
    }

    &__grid{
        margin-top: 35px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        @media (max-width: $ipadpro-screen) {
            flex-wrap: wrap;
        }

        @media (max-width: $mobile-screen) {
            display: none !important;
        }

        > div {
            @media (max-width: $ipadpro-screen) {
                &:nth-child(4){
                    margin-right: 0;
                    display: none !important;
                }
            }
        }

        &-item{
            position: relative;
            min-height: 276.12px;
            padding: 34px 26px;
            padding-top: 45px;
            text-align: left;
            margin-right: 15px;

            &:last-child{
                margin-right: 0;
            }

            @media (max-width: $ipadpro-screen) {
                width: 33.33%;
                max-width: 33.33%;
                margin-bottom: 34px;
            }

            @media (max-width: $mobile-screen) {
                padding: 34px 26px;
                width: auto;
                max-width: max-content;
            }

            > img{
                z-index: 2px;
            }

            > div{
                color: $color-title;
                > h5{
                    position: relative;
                    z-index: 2;
                }
    
                > p{
                    font-size: 14px;
                    line-height: 24px;
                    position: relative;
                    z-index: 2;
                }
            }
        }

        &-img-service{
            margin-bottom: 20px;
            width: 36px;
            height: 40px;
            object-fit: contain;
            width: auto !important;
        }

        &-img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        &-arrow{
            @media (max-width: $mobile-screen) {
                display: none !important;
            }

            img{
                width: 42px;
                height: 22.78px;
                margin: 0 10px;
            }
        }

        > div{
            &:last-child{
                display: none !important;
            }
        }
    }


    &__grid-owl{
        display: none !important;

        @media (max-width: $mobile-screen) {
            display: block !important;
        }
    }
}