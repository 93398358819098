.header{
    z-index: 1034;
    background-color: transparent;
    position: fixed;
    left: 0;
    width: 100%;
    top: 0;
    padding: 16px 32px;
    // backdrop-filter:  saturate(180%) blur(6px);
    border-bottom: solid 1px;
    border-color: rgba(255, 255, 255, 0.24);
    background-color: rgba(0, 0, 0, 0.34);

    @media (max-width: $mobile-screen) {
        padding: 8px 16px;

        > div{
            padding: 0;
        }
    }

    .menu-icon {
        position: relative;
        width: 50px;
        height: 50px;
        cursor: pointer;
    
        .menu-icon__cheeckbox {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
            cursor: pointer;
            z-index: 2;
            -webkit-touch-callout: none;
            position: absolute;
            opacity: 0;
        }

        div {
            margin: auto;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            width: 22px;
            height: 12px;
        }

        span {
            position: absolute;
            display: block;
            width: 100%;
            height: 2px;
            background-color: var(--bar-bg, #FFFFFF);
            border-radius: 1px;
            transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);
    
            &:first-of-type {
                top: 0;
            }
            &:last-of-type {
                bottom: 0;
            }
        }

        &.active,
        .menu-icon__cheeckbox:checked + div {
            span {
                &:first-of-type {
                    transform: rotate(45deg);
                    top: 5px;
                }
                &:last-of-type {
                    transform: rotate(-45deg);
                    bottom: 5px;
                }
            }
        }
    
        &.active:hover span:first-of-type,
        &.active:hover span:last-of-type,
        &:hover .menu-icon__cheeckbox:checked + div span:first-of-type,
        &:hover .menu-icon__cheeckbox:checked + div span:last-of-type {
            width: 22px;
        }
    
        &:hover {
            // no need hover effect on mobile.
            @media (min-width: 1024px) {
                span:first-of-type {
                    width: 26px;
                }
    
                span:last-of-type {
                    width: 12px;
                }
            }
        }
    }
}