
.wrapper{
    position: relative;

    // @media (max-width: $ipad-screen) {
    //     margin-left: -300px;
    // }

    &.show-menu{
        transform:
        perspective(75em) rotatey(56deg) translate(32px, 10px);
        height: 100vh;
        transition: all 0.5s ease-in-out;
        overflow: hidden;

        @media (max-width: $ipad-screen) {
            transform: none;
            height: auto;
        }
    }

    &__list-menu{
        position: fixed;
        z-index: 99;
        right: 0;
        top: 0;
        border: solid;
        width: 424px;
        max-width: 424px;
        height: 100vh;
        display: none;
        transition: all 0.5s ease-in-out;
        background-color: rgba($color: #000000, $alpha: 0.5);

        @media (max-width: $ipad-screen) {
            width: 100%;
            max-width: 100%;
            z-index: 999;
        }
    }

    &__banner{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        display: block;
        z-index: 1024;
        
        > span{
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 56px;
            letter-spacing: 0.3em;
            text-transform: uppercase;
            position: absolute;
            bottom: 20px;
            left: 50%;
        }
    }

    &__main{
        width: 100vw;
        position: relative;
        padding: 0;
        transition: all 0.5s ease-in-out;
        margin-left: 0;

        &.content-show-menu{
            margin-left: 0;

            @media (max-width: $ipad-screen) {
                margin-left: -364px;
            }
        }

        &.show-menu{
            display: block !important; 
            padding: 102px;
            width: calc(100% - 300px);
            height: 100vh;
            perspective: 500px;
            overflow: hidden;

            > div{
                transition: all 1.2s linear;
                -webkit-animation: two 1.5s infinite;
                animation: two 5s infinite;
                background-color: blue;
                -webkit-transform: rotateY(15deg);
                transform: rotateY(15deg);
                height: calc(100vh - 206px);
            }
        }
    }

    &__content{
        position: relative;
        z-index: 105;
        background-color: #FFFFFF;
        width: 100vw;
    }
} 