@import 'variables';
@import 'global';
@import 'font';

@import 'bootstrap-scss/bootstrap';

@import 'bootstrap/button';
@import 'bootstrap/collesop';
@import 'owl-custom';

@import 'typography';
@import './style.css';

// @import './other/owl.carousel.css';
// @import './other/owl.theme.default.css';

@import 'partial/header';
@import 'partial/banner';
@import 'partial/menu';
@import 'partial/tout';
@import 'partial/about';
@import 'partial/services';
@import 'partial/feature';
@import 'partial/price';
@import 'partial/testimonial';
@import 'partial/faq';
@import 'partial/footer';


@import 'app';