.price{
    @media (max-width: $mobile-screen) {
        padding: 80px 20px;

        > div{
            padding: 0;
        }
    }

    &__wrap-title{
        margin-bottom: 83px;
        max-width: calc(100% - 375px);

        @media (max-width: $ipad-screen) {
            max-width: 100%;
            margin-bottom: 68px;
        }
    }

    &--label{
        font-weight: 300;
        font-size: 76px;
        line-height: 62px;
        margin-bottom: 42px;

        >sup{
            top: -44px; 
        }

        > sup, > sub{
            font-size: 16px;
            line-height: 30px
        }
    }

    &__grid{
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: $mobile-screen) {
            display: block;
        }
    }

    &__item{
        border: 1px solid #EEEDED;
        box-shadow: 0px 4px 50px rgba(58, 64, 215, 0.17);
        border-radius: 10px;
        background-color: #FFFFFF;
        text-align: center;
        flex-direction: column;
        padding: 78px 20px;
        position: relative;
        z-index: 1;
        height: fit-content;
        width: 471px;

        @media (max-width: $mobile-screen) {
            max-width: 100%;
            margin-bottom: 24px;
            width: 100%;
        }

        &.active{
            background-color: $color-primary;
            position: relative;
            color: black;
            width: 549px;
        }

        > h4{
            letter-spacing: 0.4em;
            text-transform: uppercase;
            margin-bottom: 28px;
        }

        >ul {
            padding-left: 0;
            margin-bottom: 53px;

            > li{
                list-style: none;
                font-weight: 300;
                font-size: 18px;
                line-height: 39px;
            }
        }

        &-btn{
            width: 250px; 
            position: relative;
            z-index: 2;
        }

        &-attr{
            position: absolute;
            right: 0;
            bottom: 0;
            width: 274px;
        }
    }

    &__attr-1{
        position: absolute;
        right: 0;
        bottom: 50%;
        width: 240px;
        transform: translate(10%, -116%);

        @media (max-width: $mobile-screen) {
            transform: translate(11%, -30%);
        }
    }

    &__attr-2{
        position: absolute;
        left: 0;
        top: 0;
        width: 202px;
        transform: translate(15%, -4%);

        @media (max-width: $mobile-screen) {
            transform:translate(-12%, -40%);
        }
    }

    &__attr-3{
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 220px;
        transform: translate(-79%, 38%);

        @media (max-width: $mobile-screen) {
            transform: translate(-73%, 38%);
        }
    }
}