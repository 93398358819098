.wrap-banner{
    position: relative;
    background-color: rgba($color: #000000, $alpha: 0.5);
    height: 100vh;

    &__comtom-grid{
        margin: 0;
    }

    &__grid{
        border-right: solid rgba($color: #FFFFFF, $alpha: 0.25);
        display: block;
        height: 100vh;
        padding: 0;
        background-color: #000000;
        position: relative;

        @media (max-width: $mobile-screen) {
            display: none;
        }

        &:first-child, &:nth-child(5){

            @media (max-width: $mobile-screen) {
                display: block;
            }

            &::after{
                content: "";
                width: 100%;
                height: calc(100vh - 70%);
                display: block;
                border-right: solid rgba($color: #FFFFFF, $alpha: 0.5);
                position: absolute;
                bottom: 0;
                right: -1px;
            }

            &::before{
                content: "";
                width: 9px;
                height: 9px;
                display: block;
                background-color: white;
                position: absolute;
                right: -4.5px;
                bottom: 30%;
                z-index: 99;
            }
        }

        &:nth-child(3){

            @media (max-width: $mobile-screen) {
                display: block;
            }

            &::after{
                content: "";
                width: 100%;
                height: calc(100vh - 70%);
                display: block;
                border-right: solid rgba($color: #FFFFFF, $alpha: 0.5);
                position: absolute;
                top: 0;
                right: -1px;
            }

            &::before{
                content: "";
                width: 9px;
                height: 9px;
                display: block;
                background-color: white;
                position: absolute;
                right: -4.5px;
                top: 30%;
                z-index: 99;
            }
        }
    }

    &__content{
        max-width: 650px;
        width: 650px;
        margin: auto;
        position: absolute;
        bottom: 50%;
        left: 50%;
        transform: translate(-50%, 50%);
        text-align: center;

        @media (max-width: $mobile-screen) {
            width: 100%;
        }
    }
    
    &__sub-title{
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.8em;
        margin-bottom: 20px;
        text-transform: uppercase;
    }
    
    &__title{
        font-weight: 700;
        font-size: 32px;
        line-height: 46px;
        letter-spacing: 0.5em;
        text-transform: uppercase;
        margin-bottom: 20px;

        @media (max-width: $mobile-screen) {
            font-size: 24px;
            line-height: 36px;
        }
    }

    &__progress{
        background-color: #403C3C;
        width: 213px;
        height: 2px;
        margin: auto;
        margin-top: 10px;

        >div{
            background-color: $color-primary;
            width: 1%;
            height: 2px;
        }
    }

    &--progress-count{
        font-weight: 300;
        font-size: 18px;
        line-height: 56px;
    }

    > span{
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 56px;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        position: absolute;
        bottom: 20px;
        left: 50%;
    }
}