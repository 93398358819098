/*
Typography
=========================== */
html,
body {
    padding: 0;
    margin: 0;
    min-height: var(--vh);
    width: 100vw;
    overflow-x: hidden !important;
    overflow-y: scroll !important;
    position: relative;

}

body {
	color: var(--color-paragraf);
    font-family: $font-family;
    font-weight: 400;
    font-size: 16px;
    line-height: 36px;
    z-index: 1;
    background-color: #000000 !important;
    padding: var(--padding);

    @media (max-width: $mobile-screen) {
        font-size: 14px;
    }
    
    &.hidden{
        overflow-y: hidden !important;
        width: 100%;
        height: 100vh;
        
        @media (max-width: $ipad-screen) {
            overflow-y: scroll !important;
        }
    }
}

a{
	outline:0;
    cursor: pointer;
    color: var(--color_primary);
    text-decoration: none;

    &:focus{
        outline: 0;
    }

    &:hover{
        color: var(--txt_color_2);
    }
}

h1{
	color: var(--color-title);
	margin: 0 0 15px 0;
	font-size: 40px;
    font-family: $font-family;
    line-height: 56px;
    letter-spacing: 0.25px;
    font-weight: 400;
    text-transform: none;

    @media (max-width: $mobile-screen) {
        font-size:32px;
	    line-height:46px;
    }
}

h2{
    @extend h1;
	font-size:32px;
	line-height:46px;

    @media (max-width: $mobile-screen) {
        font-size:24px;
	    line-height:36px;
    }
}

h3{
    @extend h1;
	font-size:24px;
	line-height:36px;

    @media (max-width: $mobile-screen) {
        font-size:20px;
	    line-height:32px;
    }
}

h4{
    @extend h1;
	font-size:20px;
	line-height:32px;

    @media (max-width: $mobile-screen) {
        font-size:18px;
	    line-height:30px;
    }
}

h5{
    @extend h1;
	font-size:18px;
	line-height:30px;

    @media (max-width: $mobile-screen) {
        font-size:16px;
	    line-height:28px;
    }
}

h6{
    @extend h1;
	font-size:16px;
	line-height:28px;

    @media (max-width: $mobile-screen) {
        font-size: 14px;
        line-height: 26px;
    }
}

p{
    margin-top: 0;
    margin-bottom:$sp-4;

    @media (max-width: $mobile-screen) {
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
    }
}

span{
    font-size: 14px;
    line-height: 26px;

    @media (max-width: $mobile-screen) {
        font-size: 12px;
        line-height: 16px;
    }
}

section{
    padding: 100px 0;

    @media (max-width: $mobile-screen) {
        padding: 80px 0;
    }
}

label{
    font-weight: 600;
    color: var(--txt_color_1);
}

strong{
    color: var(--txt_color_1)
}