.faq{
    &__wrap-title{
        margin-bottom: 83px;

        @media (max-width: $mobile-screen) {
            margin-bottom: 27px;
        }
    }

    &--img{
        width: 100%;
        object-fit: cover;
        z-index: 2;
        position: relative;
    }

    &--attr{
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
        width: 240px;
        transform: translate(16%, 0%);

        @media (max-width: $ipadpro-screen) {
            width: 182px;
            transform: translate(10%, 24%);
        }

        @media (max-width: $ipad-screen) {
            transform: translate(-5%, 32%);
        }

        @media (max-width: $mobile-screen) {
            width: 160px;
            transform: translate(-5%, 32%);
        }
    }

    &__wrap-img{
        padding-top: 98px;
        padding-left: 47px;
    }
}