.menu {
    background-color: rgba($color: #000000, $alpha: 0.9);

    > div {
        padding: 18px 36px;
        
        > ul{
            display: block;
            width: 100%;

            > li {
                padding: 0;
            }
        }
    }

    &__link{
        display: block;
        width: 100%;
        color: #858585;
        font-weight: 300;
        font-size: 24px;
        line-height: 60px;
        letter-spacing: 0.3em;
        padding: 0;

        &.active{
            color: #FFFFFF !important;
        }

        &:hover, &:focus{
            color: #FFFFFF;
        }
    }
}