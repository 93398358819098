.testimonial{
    padding: 100px 0;
    background-color: #222222;
    position: relative;
    z-index: 1;
    color: white;
    height: 886px;

    @media (max-width: $mobile-screen) {
        height: 786px;

        >div{
            padding: 0;
        }
    }

    &__wrap-title{
        margin-bottom: 83px;
        max-width: calc(100% - 375px);

        @media (max-width: $ipad-screen) {
            max-width: 100%;
            padding: 20px;
            margin-bottom: 34px;
        }
    }

    &__grid{
        position: relative;

        &-content{
            position: relative;
            min-height: 363px;
            height: 363px;
            padding: 24px 32px;

            @media (max-width: $mobile-screen) {
                padding: 20px;
                height: 239px;
                min-height: 239px;
            }

            > p{
                display: -webkit-box;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;  
                overflow: hidden;
            }
        }

        &-user{
            position: relative;
            bottom: 25px;
            left: 80px;
            z-index: 100;
            display: inline-block;

            @media (max-width: $mobile-screen) {
                bottom: 20px;
                left: 57px;
            }
        }

        &-img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
    }
}