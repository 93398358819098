.btn{
    padding: 15px;
    border-radius: 30px;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;

    &.btn-primary{
        background-color: $color-primary;
        border-color: $color-primary;
        color: $color-title;

        &:focus{
            background-color: $color-primary;
            border-color: $color-primary;
            color: $color-title;
        }
    }

    &.btn-sub-primary{
        background-color: $color-content;
        border-color: $color-content;
        color: #FFFFFF;
    }
}