.footer{
    background-color: #222222;
    color: #FFFFFF;
    padding: 100px 0;

    @media (max-width: $mobile-screen) {
        padding: 80px 20px;

        > div{
            padding: 0;
        }
    }

    &--title{
        margin-bottom: 30px;

        @media (max-width: $mobile-screen) {
            margin-bottom: 11px;
        }
    }

    &__desc{
        font-weight: 400;
        font-size: 14px;
        line-height: 26px;
        width: calc(100% - 100px);

        @media (max-width: $mobile-screen) {
            width: 100%;
            margin-bottom: 36px;
        }
    }

    &__contact{
        padding-left: 0;

        > li{
            list-style: none;
        }
    }

    &__divider{
        display: block;
        height: 1px;
        opacity: 0.3;
        width: 100%;
        background-color: #FFFFFF;
        position: relative;
        z-index: 1;
    }

    &__sub-footer{
        background-color: #222222;
        color: #FFFFFF;
        padding: 33px 0;
        margin-top: -1px;
    }
}