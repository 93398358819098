.tour{
    // height: 100vh;
    position: relative;
    // scroll-behavior: smooth;

    .card-xx{
        text-align: center;
        height: 100vh;
        width: 100%;
        position: relative;

        &.hidden-tour{
            &:nth-child(1){
                position: relative;

                @media (max-width: $ipad-screen) {
                    position: fixed;
                }
            }
        }
    
        &:nth-child(even){
            background-color: gray;
        }
    
        &:nth-child(1){
            position: fixed;
            top: 0px;
        }
    
        &:nth-child(2){
            margin-top: 100vh;
        }
    
        .wrap-tour{
            background-color: rgba($color: #000000, $alpha: 0.3);
            height: 100vh;
            width: 100%;
            position: relative;
            z-index: 99;
            top: 0;
            left: 0;
            // pointer-events: none;

            &:nth-child(1){
                position: absolute;
                top: 0px;
            }
        
            &:nth-child(2){
                margin-top: 100vh;
            }
        }

        > iframe{
            height: 100vh;
            width: 100%;
        }
    
        > img{
            height: 100vh;
            width: 100%;
        }

        > a {
            position: absolute;
            z-index: 101;
            bottom: 50%;
            left: 50%;
        }
    }

    &__menu{
        position: fixed;
        left: 50px;
        bottom: 50px;
        z-index: 103;

        @media (max-width: $ipad-screen) {
            left: 24px;
            bottom: 80px;
        }

        &-tour{
            padding: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 30px;
            color: #858585;
            opacity: 0.9;
            text-transform: uppercase;
            letter-spacing: 0.3em;

            &.active, &:hover{
                color: #FFFFFF !important;
            }

            &:focus{
                color: #858585;
            }
        }
    }
}